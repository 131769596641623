import styled from '@emotion/styled';
import { MultipleSwitchItemProps, MultipleSwitchProps } from '.';

export const StyledMultipleSwitch = styled.div<Pick<MultipleSwitchProps, 'theme'>>`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-content: center;
	align-items: stretch;
	min-height: 40px;
	overflow-x: auto;
	border-radius: 8px;

	${({ theme }) => theme.shadows[5]}
	border: 1px solid ${({ theme }) => theme.colors.grey_shades_with_blue[200]};
	${({ theme }) => theme?.typography.textMedium300};
`;

export const ItemWrapper = styled.label<
	Pick<MultipleSwitchItemProps, 'disabled' | 'active' | 'flex'>
>`
	display: flex;
	${({ flex }) => {
		switch (flex) {
			case 'content':
				return 'flex: 1 1 auto;';
			case 'same':
				return 'flex: 1 1 0;';
			default:
				return `flex: ${flex}`;
		}
	}}

	background: ${({ active, theme }) =>
		active ? theme.colors.grey_shades_with_blue[25] : theme.colors.white};
	border-right: 1px solid ${({ theme }) => theme.colors.grey_shades_with_blue[200]};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

	input {
		position: absolute;
		opacity: 0;
		display: none;

		&:checked ~ .item {
			background: ${({ theme }) => theme.colors.grey_shades_with_blue[25]};
		}
	}

	&:hover {
		background: ${({ active, disabled, theme }) => {
			if (active) {
				return theme.colors.grey_shades_with_blue[25];
			} else {
				return disabled ? theme.colors.white : theme.colors.grey_shades_with_blue[25];
			}
		}};
	}

	&:last-child {
		border-right: 1px solid transparent;
	}
`;

export const StyledMultipleSwitchItem = styled.div<
	Pick<MultipleSwitchItemProps, 'active' | 'theme' | 'tooltip' | 'disabled'>
>`
	display: flex;
	flex: 1 1 auto;
	order: 0;
	align-self: auto;
	transition: all 200ms ease-out;
	text-align: center;
	color: ${({ theme: { colors }, disabled }) =>
		disabled ? colors.grey_shades_with_blue[300] : colors.grey_shades_with_blue[600]} !important;

	.tooltip {
		width: 100%;
		justify-content: ${({ tooltip }) => (tooltip ? 'space-between' : 'center')};
		align-content: center;
		align-items: stretch;
		display: flex;
		> div {
			flex: 1 1 auto;
			order: 0;
			align-items: center;
			display: flex;
			justify-content: center;
		}
	}

	.tooltipWrapper {
		height: 100%;
		justify-content: center;
		flex: 1;

		[data-tippy-root] {
			display: flex;
		}
	}
`;

import gql from 'graphql-tag';

export default gql`
	mutation acknowledgePricingChange($lockPrice: Boolean, $message: String) {
		acknowledgePricingChange(lockPrice: $lockPrice, message: $message) {
			success
			message
		}
	}
`;

import { IntlShape } from 'react-intl';

export default (intl: IntlShape) => ({
	DIRECT: intl.formatMessage({ id: 'app.results.source-direct-link' }),
	IFRAME: intl.formatMessage({ id: 'app.results.source-survey-widget' }),
	LINKEDIN: 'Linked In',
	EMAIL: intl.formatMessage({ id: 'app.results.source-invitation' }),
	POPUP: intl.formatMessage({ id: 'app.results.source-pop-up' }),
	GPLUS: 'Google+',
	CINT: intl.formatMessage({ id: 'app.results.source-consumer-panel' }),
	FB_WALL: intl.formatMessage({ id: 'app.results.source-facebook' }),
	VKONTAKTE: 'VKontakte',
	TWITTER: intl.formatMessage({ id: 'app.results.source-twitter' }),
	QR_CODE: intl.formatMessage({ id: 'app.results.source-qrcode' }),
	POPOVER: intl.formatMessage({ id: 'app.results.source-pop-over-widget' }),
	WEB_LINK: intl.formatMessage({ id: 'app.results.source-button' }),
	SOC_NETWORK: intl.formatMessage({ id: 'app.results.source-other-sm' }),
	WHATSAPP: intl.formatMessage({ id: 'app.results.source-whatsapp' }),
});

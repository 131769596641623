export { PAGES, PAGES_PATH } from './pages';
export { default as HEADER } from './header';
export { default as SETTINGS } from './settings';
export { default as TIMEZONES } from './timezones';
export { default as DURATIONS } from './durations';
export { default as COMPLETENESS } from './completeness';
export { default as SOURCES } from './sources';
export { default as ROLES } from './roles';
export { PLANS, PRO_PLANS, BASIC_PLANS, PLANS_NEW, PLANS_24 } from './plans';
export { EXPERIMENTS, EXPERIMENT_STATE, AB_BM_VARIANT } from './experiments';
export { SERVER_LOCATION } from './serverLocation';
export { default as RELEVANT_COUNTRIES } from './relevantCountries';
export { default as FREE_MAIL_DOMAINS } from './freeMailDomains';
export { default as STRATEGY } from './responseLimitStrategy';
export { default as NOTIFICATIONS } from './notifications';
export { default as UPGRADE_MODAL_TYPES } from './upgradeModalStripeTypes';
export { default as PERIOD } from './period';
export { EXPIRATION_WARNING_LIMIT } from './expirationLimits';
export { default as LOCATIONS, UTM_PREFIX } from './locations';
export { default as EVENTS } from './events';
export { QUESTION_TYPES } from './questionTypes';
export { LOGIN_URL_SUFFIX } from './loginUrlSuffix';
export { TOOLTIP_BUILDER_WP } from './tours';

// SSR fix
const { env } = typeof window === 'undefined' ? { env: {} } : window;
export const API = `https://${env?.API}`;
export const WS = `wss://${env?.API}`;
export const AUTH = env?.AUTH;

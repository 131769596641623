import React, { PureComponent, Suspense, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { TweenLite } from 'gsap';
import { ApolloProvider } from '@apollo/client';
import { compose } from 'redux';
import moment from 'moment';
import { BroadcastChannel } from 'broadcast-channel';
import { IntlProvider } from 'react-intl';
import {
	getActivePage,
	normalizeTranslate,
	reformatExperimentsForTracking,
	tryParseJSONObject,
} from '~/utils';
import auth from '~/auth';

import Notifications from '~/survio-ui/Notifications';
import ModalContainer from '../../containers/ModalContainer';
import NotificationsNew from '../Notifications';

import {
	EXPERIMENT_STATE,
	EXPERIMENTS,
	FREE_MAIL_DOMAINS,
	PAGES,
	RELEVANT_COUNTRIES,
} from '~/constants';

import Loader from '~/components/Loader';

import MainLoaderProvider, { MainLoaderContext } from '~/components/App/components/MainLoader';

import mutation from '~/cache/mutation';
import query from '~/cache/query';

import ErrorBoundary from '../../components/ErrorBoundary';
import { survioTheme, ThemeProvider } from 'ui';
import { PRICING_EXPERIMENT, SEGMENTATION_VARIANT } from '~/constants/experiments';
import UpgradeModalContainer from '~/containers/UpgradeModalContainer';
import InAppProvider from '~/components/InApps/context';
import { Helmet } from 'react-helmet';
import { InAppContentProvider } from '~/components/InAppContentProvider';
import InAppContent from '~/components/InAppContent';
import withRouter from '~/hoc/withRouter';

const AppLayout = React.lazy(() => import('../../components/App'));
const Dashboard = React.lazy(() => import('../../pages/Dashboard'));
const DashboardExport = React.lazy(() => import('../../pages/DashboardExport'));
const ExportTile = React.lazy(() => import('../../pages/DashboardExport/ExportTile'));
const ExportTileChart = React.lazy(() => import('../../pages/DashboardExport/ExportTileChart'));
const Wizardd = React.lazy(() => import('../../pages/Wizardd'));
const Builder = React.lazy(() => import('../../pages/Builder'));
const BuilderNew = React.lazy(() => import('../../pages/Builder2023'));
const BuilderNew1 = React.lazy(() => import('../../pages/Builder23_1'));
const Settings = React.lazy(() => import('../../pages/Settings'));
const GetResponses = React.lazy(() => import('../../pages/GetResponses'));
const Results = React.lazy(() => import('../../pages/Results'));
const Account = React.lazy(() => import('../../pages/MyAccount'));
const NotFound = React.lazy(() => import('../../pages/NotFound'));
const Pricing = React.lazy(() => import('../../pages/Pricing'));
const StripePricing = React.lazy(() => import('../../pages/StripePricing'));
const Pricing2023 = React.lazy(() => import('../../pages/Pricing2023'));
const Sandbox = React.lazy(() => import('../../components/Sandbox'));
const Search = React.lazy(() => import('../../pages/Search'));
const AdvanceInvoice = React.lazy(() => import('../../pages/AdvanceInvoice'));
const Segmentation = React.lazy(() => import('../../components/Segmentation'));

class AppRoute extends PureComponent {
	static getDerivedStateFromProps({ location: { pathname } }, prevState) {
		if (pathname !== prevState.pathname) {
			window.scrollTo(0, 0);
			const animateNode = !(!!prevState.pathname && pathname.includes(prevState.pathname));

			return {
				animateNode,
				pathname: getActivePage(pathname).path.replace('/:uid', '').split('/')[1],
			};
		}
		return null;
	}

	state = { pathname: '' };

	render() {
		const { location, experiments, pricingExperiment } = this.props;
		if (!getActivePage(location?.pathname)) {
			return <Navigate to={PAGES['not-found'].path} />;
		}

		const { animateNode } = this.state;

		return (
			<AppLayout location={location}>
				<Transition
					in
					appear
					mountOnEnter
					unmountOnExit
					timeout={800}
					key={location.pathname}
					onEnter={(node) => {
						if (!animateNode) {
							node = document.getElementById('page') || node;
						}
						TweenLite.fromTo(node, 0.6, { opacity: 0 }, { opacity: 1, delay: 0.2 });
					}}
				>
					<Suspense fallback={<Loader visible />}>
						<Routes>
							<Route exact path="/" element={<Dashboard />} />
							<Route exact path="/not-found" element={<NotFound />} />
							<Route
								exact
								path="/pricing"
								element={
									[PRICING_EXPERIMENT.TEST_PRICE_23_A, PRICING_EXPERIMENT.TEST_PRICE_24_A].includes(
										pricingExperiment,
									) ? (
										<Pricing2023 />
									) : pricingExperiment === PRICING_EXPERIMENT.AB_BUSINESS_MODEL_01 ? (
										<StripePricing />
									) : (
										<Pricing />
									)
								}
							/>
							<Route exact path="/wizard" element={<Wizardd />} />
							<Route path="/account/*" element={<Account />} />
							<Route path="/search" element={<Search />} />
							<Route path="/advance_invoice" element={<AdvanceInvoice />} />
							<Route path="/:uid/*" element={<SurveyValidation experiments={experiments} />} />
						</Routes>
					</Suspense>
				</Transition>
			</AppLayout>
		);
	}
}

AppRoute.propTypes = {
	loading: PropTypes.bool,
	location: {
		pathname: PropTypes.string,
	},
	sendTracking: PropTypes.func,
	experiments: PropTypes.array,
	pricingExperiment: PropTypes.string,
};

const AppRouteW = withRouter(AppRoute);

const App = ({ loading, survey, experiments }) => {
	if (loading) {
		return <Loader visible />;
	}
	if (survey === null) {
		return <Navigate to={PAGES['not-found'].path} />;
	}

	const hasNewRC = experiments?.some(
		(e) =>
			e.name === EXPERIMENTS.RC && e.variant !== 'old' && e.state === EXPERIMENT_STATE.INCLUDED,
	);

	return (
		<>
			{/* Google Tag Manager */}
			{hasNewRC && (
				<>
					<Helmet>
						<script>{`
							(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
							new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
							j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
							'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
							})(window,document,'script','dataLayer','GTM-KL2QKCFF');
						`}</script>
					</Helmet>
					<noscript>
						<iframe
							src="https://www.googletagmanager.com/ns.html?id=GTM-KL2QKCFF"
							height="0"
							width="0"
							style="display:none;visibility:hidden"
						></iframe>
					</noscript>
				</>
			)}
			<Routes>
				{/* <Route path="preview" element={hasNewRC ? <BuilderNew1 /> : <Builder />} /> */}
				<Route path="builder" element={hasNewRC ? <BuilderNew1 /> : <Builder />} />
				<Route path="settings/*" element={<Settings />} />
				<Route path="get-responses" element={<GetResponses />} />
				<Route path="results/*" element={<Results />} />
			</Routes>
		</>
	);
};

App.propTypes = {
	match: PropTypes.object,
	loading: PropTypes.bool,
	survey: PropTypes.object,
	experiments: PropTypes.array,
};

const SurveyValidation = compose(
	memo,
	withRouter,
	query('survey', {
		fragments: 'Title',
		options: (ownProps) => ({
			variables: { uid: ownProps.params.uid },
		}),
	}),
)(App);

const TrackingData = ({
	userId,
	email,
	createdAt,
	languageOverride,
	plan,
	country,
	trialData,
	registerSegmentation,
	updateExperiment,
	experiments,
}) => {
	const regSegData = tryParseJSONObject(registerSegmentation?.data);

	useEffect(() => {
		const trackingData = {
			userId,
			email,
			createdAt,
			languageOverride,
			customAttributes: {
				...(registerSegmentation && !regSegData.sentToTracking
					? {
							relevant_area: RELEVANT_COUNTRIES.includes(country),
							pro_email: !FREE_MAIL_DOMAINS.includes(email?.split('@')[1]),
							segment_area_of_use: regSegData.answers?.areaOfUse,
							segment_purpose: regSegData?.answers?.purpose,
							segment_orgsize: regSegData?.answers?.organisationSize,
							segment_area_of_use_business_or_public: ['business', 'public'].includes(
								regSegData?.answers?.areaOfUse,
							),
						}
					: {}),
				trial: trialData.isTrial,
				...(trialData.isTrial
					? {
							trial_starts_at: trialData?.activeSince,
							trial_ends_at: trialData?.activeTill,
							trial_source: trialData?.trialSource,
						}
					: {}),
				plan: trialData?.isTrial ? `Trial ${plan}` : plan,
				country,
				viewport_width: window.innerWidth,
				viewport_height: window.innerHeight,
				...reformatExperimentsForTracking(experiments),
			},
		};
		const smartlookData = {
			userId,
			email,
			createdAt,
			language: languageOverride,
			...trackingData.customAttributes,
		};

		if (window.env.SMARTLOOK === 'true') {
			smartlook('identify', email, smartlookData);
		}

		if (registerSegmentation && !regSegData.sentToTracking && updateExperiment) {
			updateExperiment({
				input: {
					name: EXPERIMENTS.SEGMENTATION,
					data: JSON.stringify({ sentToTracking: true, ...regSegData }),
				},
			});
		}
	}, [
		createdAt,
		email,
		languageOverride,
		plan,
		userId,
		country,
		trialData,
		registerSegmentation,
		regSegData.answers?.areaOfUse,
		regSegData.answers?.purpose,
		regSegData.answers?.organisationSize,
		updateExperiment,
		regSegData.sentToTracking,
		regSegData,
		experiments,
	]);

	return null;
};

TrackingData.propTypes = {
	userId: PropTypes.string,
	email: PropTypes.string,
	createdAt: PropTypes.string,
	languageOverride: PropTypes.string,
	plan: PropTypes.string,
	country: PropTypes.string,
	trialData: PropTypes.object,
	registerSegmentation: PropTypes.object,
	updateExperiment: PropTypes.func,
	experiments: PropTypes.array,
};

class RootRoutes extends React.Component {
	static getDerivedStateFromProps({ appLang, appStrings, loading }, prevState) {
		if (appLang !== prevState.appLang && appStrings && !loading) {
			moment.locale(appLang);
			window.addthis_config = window.addthis_config || {};
			window.addthis_config.lang = appLang || 'en';
			window.addthis_config.services_expanded = '';
			return { appLang, appStrings: normalizeTranslate(appStrings) };
		}
		return null;
	}

	state = {
		loadingLanguage: false,
	};

	componentDidMount() {
		if (window.env.SMARTLOOK === 'true') {
			smartlook('consentAPI', 'api');
		}

		const channel = new BroadcastChannel('appLang');
		channel.onmessage = (language) => this.props.updateLanguage({ language });
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.appLang !== this.props.appLang) {
			this.setState({ loadingLanguage: true });
			setTimeout(() => this.setState({ loadingLanguage: false }));
		}
	}

	render() {
		const {
			loading,
			isTrial,
			email,
			registeredAt,
			serviceName,
			trialData,
			updateExperiment,
			experiments,
			country,
		} = this.props;
		const { appLang, appStrings, loadingLanguage } = this.state;
		const jwtData = auth.getTokenData(auth.getToken());

		if (appLang === 'br') {
			moment.locale('pt-br');
		}

		const pricingExperiment = this.props.stripePriceList?.experiment;

		const segmentation = experiments.find(
			(e) => e.name === EXPERIMENTS.SEGMENTATION && e.state === EXPERIMENT_STATE.INCLUDED,
		);

		return loading || loadingLanguage ? (
			<Loader visible />
		) : (
			<IntlProvider key={`app-${appLang}`} locale={appLang} messages={appStrings}>
				<InAppContentProvider>
					<React.Fragment>
						<TrackingData
							email={email}
							plan={serviceName}
							userId={jwtData.userId}
							createdAt={registeredAt}
							languageOverride={appLang}
							country={country?.code}
							trialData={{ isTrial, ...trialData }}
							registerSegmentation={experiments?.find(
								(e) =>
									e.name === EXPERIMENTS.SEGMENTATION &&
									e.variant === SEGMENTATION_VARIANT.REGISTER,
							)}
							updateExperiment={updateExperiment}
							experiments={experiments}
						/>

						{segmentation ? <Navigate replace to={PAGES.segmentation.path} /> : null}

						<Routes>
							<Route exact path={PAGES.dashboardExport.path} element={<DashboardExport />} />
							<Route exact path={PAGES.dashboardTileExport.path} element={<ExportTile />} />
							<Route
								exact
								path={PAGES.dashboardTileChartExport.path}
								element={<ExportTileChart />}
							/>
							<Route exact path={PAGES.segmentation.path} element={<Segmentation />} />
							<Route exact path="/sandbox" element={<Sandbox />} />
							<Route
								path="*"
								element={
									<AppRouteW experiments={experiments} pricingExperiment={pricingExperiment} />
								}
							/>
						</Routes>

						<UpgradeModalContainer key={`stripeModal-${appLang}`} />
						<ModalContainer key={`modal-${appLang}`} />
						<Notifications key={`notifications-${appLang}`} />
						<NotificationsNew key={`notificationsNew-${appLang}`} />
						<InAppContent key={`inAppContent-${appLang}`} />
					</React.Fragment>
				</InAppContentProvider>
			</IntlProvider>
		);
	}
}

RootRoutes.propTypes = {
	appLang: PropTypes.any,
	experiments: PropTypes.array,
	appStrings: PropTypes.object,
	loading: PropTypes.bool,
	isTrial: PropTypes.bool,
	email: PropTypes.string,
	registeredAt: PropTypes.string,
	serviceName: PropTypes.string,
	trialData: PropTypes.object,
	updateExperiment: PropTypes.func,
	country: PropTypes.object,
	updateLanguage: PropTypes.func,
	stripePriceList: PropTypes.object,
};

class Preconnect extends React.PureComponent {
	static contextType = MainLoaderContext;

	componentDidMount() {
		this.context.showMainLoader();
	}

	render() {
		const { loading, ...props } = this.props;
		return !loading && <AppStrings {...props} />;
	}
}

Preconnect.propTypes = {
	loading: PropTypes.bool,
};

const AppLang = compose(
	query('account', {
		fragments: ['Language', 'Smartlook', 'UserContact', 'ActiveService', 'isTrial'],
		mapProps: ({ user }) => {
			if (!user) {
				return {};
			}

			return {
				email: user.email,
				isTrial: user.activeService?.isTrial,
				trialData: {
					activeSince: user?.activeService?.activeSince,
					activeTill: user?.activeService?.activeTill,
					trialSource: user?.activeService?.trialSource,
				},
				appLang: user.language,
				registeredAt: user.registeredAt,
				serviceName: user.activeService?.name,
			};
		},
	}),
	query('user', {
		fragments: ['Experiments', 'Country', 'Tours'],
		mapProps: ({ user }) => {
			if (!user) {
				return {};
			}

			return {
				experiments: user.experiments,
				country: user.country,
			};
		},
	}),
	query('user/withInput', {
		skip: ({ experiments }) =>
			!experiments?.find(
				(e) => e.name === EXPERIMENTS.AB_TRIAL && e.state === EXPERIMENT_STATE.INCLUDED,
			),
		fragments: ['Team'],
	}),
	query('user/priceList'),
	// Prefetch dat pro novy upgrade modal
	query('account', {
		fragments: ['UpgradePlanData', 'NextBilling'],
	}),
	query('stripe', {
		fragments: ['Prices'],
		options: ({ user }) => ({
			variables: {
				currencyCode: user?.activeService?.nextBilling?.currencyCode,
			},
		}),
	}),
	query('user/inAppContent'),
	mutation('updateExperiment', {
		optimisticResponse: { updateExperiment: true },
		update: ({
			getQuery,
			cache,
			variables: {
				input: { data, name },
			},
		}) => {
			const query = {
				query: getQuery('user', ['Experiments']),
			};
			const { user } = cache.readQuery(query);
			const index = user?.experiments.findIndex((e) => e.name === name);
			const newData = {
				user: {
					...user,
					experiments: [
						...user.experiments.slice(0, index),
						{ ...user?.experiments[index], data },
						...user.experiments.slice(index + 1, user.experiments.length),
					],
				},
			};
			cache.writeQuery({
				...query,
				data: newData,
			});
		},
	}),
)(Preconnect);

const AppStrings = compose(
	mutation('account/updateLanguage', {
		propName: 'updateLanguage',
		update: ({ cache, getQuery, variables }) => {
			// get data
			const query = {
				query: getQuery('account', ['Language']),
			};
			let { user } = cache.readQuery(query);
			// change data
			user = { ...user, ...variables };
			// write data
			cache.writeQuery({
				...query,
				data: { user },
			});
		},
	}),
	query('lingua'),
)(RootRoutes);

const Root = ({ store, client }) => (
	<Router>
		<ErrorBoundary>
			<ThemeProvider theme={survioTheme}>
				<MainLoaderProvider>
					<ApolloProvider client={client}>
						<Provider store={store}>
							<Suspense fallback={<Loader visible />}>
								<InAppProvider>
									<Routes>
										<Route path="*" element={<AppLang />} />
									</Routes>
								</InAppProvider>
							</Suspense>
						</Provider>
					</ApolloProvider>
				</MainLoaderProvider>
			</ThemeProvider>
		</ErrorBoundary>
	</Router>
);

Root.propTypes = {
	store: PropTypes.object,
	client: PropTypes.object,
};

export default Root;

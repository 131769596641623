import gql from 'graphql-tag';

export default gql`
	fragment ActiveService on User {
		activeService {
			autorenewal
			responseRemovalDate
			responsesRemovalCount
			name
			identifier
			period
			isTrial
			provider
			featureList
			responseLimit
			responseCollectionLimit
			yearSeatPrice
			twoYearSeatPrice
			nextBillingSeatsCount
			paidNextPeriod
			eligibleForRenewalDiscount
			nextBilling {
				date
				amount
				currencyCode
				cardExpiration
				plan {
					identifier
					name
				}
			}
			pricingChange {
				acknowledgedAt
				fromPrice
				toPrice
				emailedAt
			}
		}
	}
`;

import React from 'react';
import { EMPTY_INDIVIDUAL } from '../constants';

import Text from '~/survio-ui/Text';
import { FormattedMessage } from 'react-intl';

const choice = {
	cols: (question) => {
		const { isMultipleAnswer, choices, id, number, text, hasPictureChoices } = question;
		const cols = [];

		if (isMultipleAnswer) {
			choices.map((choice) => {
				cols.push({
					dataIndex: `${id}-${choice.id}`,
					// intlID: 'app.results.individual-questioncolumn',
					title: () => <Text maxLine={2}>{`${text} x ${choice.text}`}</Text>,
					questionId: id,
					answerId: choice.id,
					width: 270,
					render: (row) =>
						choice.open ? (
							<Text width={225}>{row[`${id}-${choice.id}`]}</Text>
						) : (
							<FormattedMessage
								id={row[`${id}-${choice.id}`] ? 'app.common.yes' : 'app.common.no'}
							/>
						),
				});
			});
		} else {
			cols.push({
				dataIndex: String(id),
				// intlID: 'app.results.individual-questioncolumn',
				title: () => <Text maxLine={2}>{text}</Text>,
				questionId: id,
				width: 270,
				render: (row) =>
					hasPictureChoices ? (
						<>
							{hasPictureChoices ? <FormattedMessage id="app.common.image" /> : null} {row[id]}
						</>
					) : (
						<Text width={225}>{row[id]}</Text>
					),
			});

			question.isQuizItem &&
				cols.push({
					dataIndex: `${id}-quizPoints`,
					intlID: 'app.results.individual-questioncolumn',
					title: (intl) => `${number} / ${intl.formatMessage({ id: 'app.designer.modal.points' })}`,
					questionId: id,
					width: 270,
				});
		}

		return cols;
	},
	rows: ({ answer, cols }) => {
		const list = answer.answer.list;
		const items = {};

		cols.map((col) => {
			if (!col.answerId) {
				items[col.dataIndex] =
					col.dataIndex === `${answer.questionId}-quizPoints`
						? list[0]?.choice.quizPoints
						: list && list[0]
							? list[0].ownText
								? list[0].ownText
								: list[0].choice.text || list[0].choice.ordinal
							: EMPTY_INDIVIDUAL;
				return false;
			}
			const res = list && list.filter((item) => item.choice.id === col.answerId)[0];
			items[col.dataIndex] = res ? (res.ownText ? res.ownText : res.choice.text) : EMPTY_INDIVIDUAL;
		});
		return items;
	},
};
export default choice;

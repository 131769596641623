import { IntlShape } from 'react-intl';

export default (intl: IntlShape) => ({
	LESS_THAN_MINUTE: `< 1 ${intl.formatMessage({ id: 'app.common.min' })}`,
	ONE_TO_TWO: `1 - 2 ${intl.formatMessage({ id: 'app.common.min' })}`,
	TWO_TO_FIVE: `2 - 5 ${intl.formatMessage({ id: 'app.common.min' })}`,
	FIVE_TO_TEN: `5 - 10 ${intl.formatMessage({ id: 'app.common.min' })}`,
	TEN_TO_THIRTY: `10 - 30 ${intl.formatMessage({ id: 'app.common.min' })}`,
	THIRTY_TO_SIXTY: `30 - 60 ${intl.formatMessage({ id: 'app.common.min' })}`,
	MORE_THEN_SIXTY: `> 60 ${intl.formatMessage({ id: 'app.common.min' })}`,
});

import React, {
	ReactNode,
	FC,
	useState,
	useLayoutEffect,
	useRef,
	useContext,
	useEffect,
	memo,
	useCallback,
} from 'react';
import chroma from 'chroma-js';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Scrollbars } from 'react-custom-scrollbars';
import { css, Global } from '@emotion/react';

import { ModalProps, ModalSidebarProps } from './index';
import {
	StyledModal,
	StyledContainer,
	StyledContent,
	StyledBody,
	StyledGradient,
	StyledFooter,
	StyledSidebar,
	SidebarImage,
	StyledHeader,
} from './Modal.styled';
import Button from '../Button';
import Portal from '../Portal';
import { useOnKeyPressed } from '../../hooks';

export type ModalContextType = {
	setSlide: React.Dispatch<React.SetStateAction<string>>;
	setPrev: React.Dispatch<React.ReactNode | null>;
	calculateStyles: () => void;
};

export const ModalContext = React.createContext<ModalContextType>(null);

function useModal() {
	return useContext(ModalContext);
}

function useWindowSize() {
	const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

	useLayoutEffect(() => {
		const updateSize = () => {
			setSize([window.innerWidth, window.innerHeight]);
		};
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	return size;
}

export const Modal: FC<ModalProps> & {
	Content: FC<ModalProps & { prev?: ReactNode; center?: boolean; width?: number | string }>;
	Footer: FC<ModalProps>;
	Header: FC<ModalProps>;
	Sidebar: FC<ModalSidebarProps>;
	useModal: () => ModalContextType;
} = ({
	children,
	close,
	closeButtonProps,
	backButtonProps,
	onBackClick,
	onCloseClick,
	hideCloseBtnOnSlides,
	withSideImage,
	hideBackBtnOnSlides,
	closeBtnClose = true,
	hideSidebarImageOnSlides,
	className,
	blurred,
	sideImageClassName,
	header,
	...props
}: ModalProps) => {
	const refModal = useRef(null);
	const [width, height] = useWindowSize();
	const [slide, setSlide] = useState(props.slide);
	const [prev, setPrev] = useState(null);

	const onEscPress = useCallback(() => {
		if (
			hideCloseBtnOnSlides === true ||
			(typeof hideCloseBtnOnSlides === 'object' && hideCloseBtnOnSlides.includes(slide))
		) {
			return;
		}
		return onCloseClick ? onCloseClick({ slide, setSlide }) : close();
	}, [close, hideCloseBtnOnSlides, onCloseClick, slide]);

	useOnKeyPressed(27, onEscPress); // 27 is keyCode for ESC

	useEffect(() => {
		const body = document.querySelector('body');
		body.style.overflow = 'hidden';
		return () => {
			body.style.overflow = '';
		};
	}, []);

	useEffect(() => {
		setSlide(props.slide);
	}, [props.slide]);

	const calculateStyles = useCallback(() => {
		if (!refModal.current) {
			return;
		}

		if (prev === slide) {
			refModal.current.classList.add('prev');
		} else {
			refModal.current.classList.remove('prev');
		}

		const refContent = [...refModal.current.querySelectorAll(StyledContent.toString())].pop();
		const refBody = [...refModal.current.querySelectorAll(StyledBody.toString())].pop();
		const refSidebar = [...refModal.current.querySelectorAll(StyledSidebar.toString())].pop();

		if (refBody) {
			if (width >= 1032) {
				refBody.style.marginLeft = `${
					refModal.current.clientWidth * 0.38 - refBody.clientWidth / 2
				}px`;
			} else {
				refBody.style.marginLeft = '';
			}

			refBody.style.width = '';
			refBody.style.width = `${refBody.clientWidth}px`;
		}

		const closeBtn = document.querySelector('.close');

		if (refSidebar) {
			if (
				closeBtn &&
				// is backgroundColor dark
				chroma(window.getComputedStyle(refSidebar).backgroundColor).get('lab.l') < 70
			) {
				closeBtn.classList.add('light');
			} else {
				closeBtn?.classList.remove('light');
			}

			if (width >= 1368) {
				refSidebar.style.width = `${
					refModal.current.clientWidth -
					(refModal.current.clientWidth * 0.38 + refBody.clientWidth / 2 + 192)
				}px`;
			} else {
				refSidebar.style.width = '';
			}
		} else {
			closeBtn?.classList.remove('light');
		}

		if (refContent) {
			refContent.classList.remove('hasScrollbar');
			const content = refContent.firstChild.firstChild;
			if (content.scrollHeight > content.clientHeight) {
				refContent.classList.add('hasScrollbar');
			}
		}
	}, [refModal, width, height, slide, prev]);

	useLayoutEffect(() => {
		calculateStyles();
	}, [refModal, width, height, slide, prev, calculateStyles]);

	const handleOnBackClick = useCallback(() => {
		onBackClick && onBackClick({ slide, prev, setSlide });
		if (!prev) {
			close();
		} else {
			setSlide(prev);
		}
	}, [close, onBackClick, prev, slide]);

	const handleOnCloseClick = useCallback(async () => {
		onCloseClick && (await onCloseClick({ slide, setSlide }));
		closeBtnClose && close();
	}, [close, closeBtnClose, onCloseClick, slide]);

	const isCloseBtnHidden =
		hideCloseBtnOnSlides &&
		(typeof hideCloseBtnOnSlides === 'boolean' || hideCloseBtnOnSlides.indexOf(slide) !== -1);

	const isBackBtnHidden =
		hideBackBtnOnSlides &&
		(typeof hideBackBtnOnSlides === 'boolean' || hideBackBtnOnSlides.indexOf(slide) !== -1);

	return (
		<>
			<Global
				styles={css`
					.fade-appear {
						opacity: 0;
						transform: scale(0.95);
					}

					.fade-appear-active {
						opacity: 1;
						transform: scale(1);
						transition:
							opacity 200ms ease-in,
							transform 200ms ease-in;
					}
				`}
			/>

			<Portal>
				<CSSTransition in appear classNames="fade" timeout={5000}>
					<StyledModal ref={refModal} className={className} blurred={blurred} id={props.id}>
						{!isBackBtnHidden && (
							<Button
								color="ghost"
								size="md"
								startIcon="arrow-back-24"
								variant="iconButton"
								className="back"
								onClick={handleOnBackClick}
								{...backButtonProps}
							/>
						)}

						{close && !isCloseBtnHidden && (
							<Button
								color="ghost"
								size="md"
								startIcon="close-24"
								variant="iconButton"
								className="close"
								onClick={handleOnCloseClick}
								{...(!closeButtonProps
									? {}
									: typeof closeButtonProps === 'object'
										? closeButtonProps
										: closeButtonProps({ slide }))}
							/>
						)}
						{header && (
							<StyledHeader className="styledHeader">
								{typeof header === 'function' ? header({ slide }) : header}
							</StyledHeader>
						)}

						<ModalContext.Provider value={{ setSlide, setPrev, calculateStyles }}>
							<StyledContainer className="styledContainer">
								<SwitchTransition mode="in-out">
									<CSSTransition key={slide || ''} timeout={{ exit: 350 }} classNames="slide">
										<div
											style={{
												display: 'flex',
												flex: '1 0 100%',
											}}
										>
											{typeof children === 'function' ? children(slide) : children}
										</div>
									</CSSTransition>
								</SwitchTransition>
							</StyledContainer>
						</ModalContext.Provider>

						{withSideImage && (
							<SidebarImage
								withSideImage={withSideImage}
								hideSidebarImageOnSlides={hideSidebarImageOnSlides}
								slide={slide}
								className={sideImageClassName}
							/>
						)}
					</StyledModal>
				</CSSTransition>
			</Portal>
		</>
	);
};

const Content = ({
	children,
	prev,
	className,
	center,
	width,
	scrollableAreaId,
	bgColor,
}: ModalProps & {
	prev?: ReactNode;
	center?: boolean;
	width?: number | string;
	scrollableAreaId: string;
	children: ReactNode;
}) => {
	const { setPrev } = useContext(ModalContext);

	useEffect(() => {
		setPrev(prev);
	}, [prev, setPrev]);

	return (
		<StyledContent bgColor={bgColor} className={className}>
			<Scrollbars
				renderView={({ style }) => (
					<div
						style={{ ...style, display: 'flex', flexDirection: 'column' }}
						id={scrollableAreaId}
					/>
				)}
			>
				<StyledBody center={center} width={width} className="styledBody">
					<StyledGradient className="styledGradient" />
					<div className="content">{children}</div>
				</StyledBody>
			</Scrollbars>
		</StyledContent>
	);
};

const Footer: FC<ModalProps> = ({
	className,
	children,
}: {
	children: ReactNode;
	className: string;
}) => <StyledFooter className={className}>{children}</StyledFooter>;

const Header: FC<ModalProps> = ({
	className,
	children,
}: {
	children: ReactNode;
	className: string;
}) => <StyledHeader className={className}>{children}</StyledHeader>;

const Sidebar: FC<ModalSidebarProps> = ({
	className,
	children,
	bgColor,
	color,
	fixedWidth,
}: ModalSidebarProps) => {
	const { calculateStyles } = useModal();

	useLayoutEffect(() => {
		calculateStyles();
	}, [calculateStyles, bgColor]);

	return (
		<StyledSidebar className={className} bgColor={bgColor} color={color} fixedWidth={fixedWidth}>
			<Scrollbars
				renderView={({ style }) => (
					<div style={{ ...style, display: 'flex', flexDirection: 'column' }} />
				)}
			>
				<div className="content">
					<StyledGradient />
					{children}
				</div>
			</Scrollbars>
		</StyledSidebar>
	);
};

Modal.Content = memo(Content);
Modal.Footer = memo(Footer);
Modal.Sidebar = memo(Sidebar);
Modal.Header = memo(Header);
Modal.useModal = useModal;

export default Modal;

import React, { FC, memo, ReactNode, useRef } from 'react';
import styled from '@emotion/styled';
import { ThemeType } from '../ThemeProvider/theme';
import cn from 'classnames';
import { useOutsideClick } from 'ui';

export type BorderProps = {
	area?: boolean;
	brightness?: number;
	children?: ReactNode;
	className?: string;
	focus?: boolean;
	hideOnFocus?: boolean;
	hideLabel?: boolean;
	hide?: boolean;
	label?: string;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
	style?: React.CSSProperties;
	theme?: ThemeType;
	remove?: boolean;
	'data-testid'?: string;
	id?: string;
	onOutsideClick?: () => void;
	disableOutsideClick?: boolean;
};

const BorderWrapper = styled.div<BorderProps>`
	position: relative;

	.border {
		border-width: ${({ focus }) => (focus ? 1 : 0)}px;
	}

	&:hover {
		.label {
			visibility: ${({ label, hideLabel }) => (label && !hideLabel ? 'visible' : 'hidden')};
		}

		.border {
			border-width: ${({ focus }) => (focus ? 1 : 2)}px;

			&::before {
				visibility: ${({ label }) => (label ? 'visible' : 'hidden')};
				opacity: ${({ hideOnFocus, focus }) => (hideOnFocus && focus ? 0 : 1)};
			}
		}
	}
`;

const Children = styled.div<BorderProps>`
	position: relative;
	border-radius: 12px;
	z-index: 10;
`;

const BorderPrimary = styled.div<BorderProps>`
	position: absolute;
	pointer-events: none;
	z-index: 20;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 12px;
	border-color: ${({ brightness, theme: { colors }, remove }) =>
		brightness > 50 && brightness < 136
			? remove
				? colors.red[550]
				: colors.primary[250]
			: remove
				? colors.red[550]
				: colors.primary[275]} !important;
	border-style: solid;
`;

const Area = styled.div<BorderProps>`
	position: absolute;
	pointer-events: none;
	z-index: 5;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 12px;
	opacity: ${({ brightness }) => (brightness < 118 ? 0.6 : 0.7)};
	border-color: ${({ brightness, theme: { colors }, hide }) =>
		hide
			? 'transparent'
			: brightness < 118
				? colors.white
				: colors.grey_shades_with_blue[800]} !important;
	border-style: dashed;
	border-width: 1px;
`;

export const Label = styled.div<BorderProps>`
	${({ theme }) => theme.typography.textSemibold200}
	visibility: ${({ focus, label, hideLabel }) =>
		label ? (focus && !hideLabel ? 'visible' : 'hidden') : 'hidden'};
	color: ${({ theme: { colors }, brightness }) =>
		brightness > 50 && brightness < 136 ? colors.primary[250] : colors.primary[275]};
	position: absolute;
	bottom: calc(100% + 8px);
	left: 0;
	z-index: 30;
`;

export const Border: FC<BorderProps> = ({
	children,
	onClick,
	className,
	area,
	onOutsideClick,
	disableOutsideClick,
	id,
	brightness = 255,
	...props
}: BorderProps) => {
	const ref = useRef(null);
	useOutsideClick(ref, onOutsideClick, disableOutsideClick);
	return (
		<BorderWrapper
			data-testid="ui-border"
			brightness={brightness}
			{...props}
			onClick={onClick}
			className={className}
			data-testid="ui-border"
			ref={ref}
			id={id}
		>
			<Children className="borderChildren">{children}</Children>
			<Label brightness={brightness} {...props} className={cn('label', 'overrideSurveyStyles')}>
				{props.label}
			</Label>
			{area && <Area className="area" brightness={brightness} {...props} />}
			<BorderPrimary brightness={brightness} {...props} className={cn('border')} />
		</BorderWrapper>
	);
};

export default memo(Border);

import { compose } from 'redux';
import { mapProps, withSendTracking } from '~/hoc';
import query from '~/cache/query';
import withCleverbridge from '~/hoc/withCleverbridge';
import { graphql } from '@apollo/client/react/hoc';
import PRICE_LIST from '~/cache/queries/user/priceList';
import { PRICING_EXPERIMENT } from '~/constants/experiments';
import { TYPES, OPS } from '~/pages/Pricing/constants';

export const withInAppData = compose(
	withSendTracking,
	query('account', {
		fragments: ['Language'],
		mapProps: ({ user }) => ({ language: user?.language }),
	}),
	query('user/inAppContent', {
		mapProps: ({ user }) => ({ userID: user?.userID }),
	}),
	withCleverbridge,
	query('account', {
		fragments: ['ActiveService'],
		mapProps: ({ user }) => ({
			planName: user?.activeService?.name,
			planIdentifier: user?.activeService?.identifier,
			actualPeriod: user?.activeService?.period,
		}),
	}),
	graphql(PRICE_LIST, {
		props: ({ data: { priceList } }) => ({
			priceList,
		}),
	}),
	query('stripe', {
		fragments: ['Prices'],
		options: { variables: {} },
		mapProps: ({ stripePriceList }) => ({
			stripePriceList,
			testPrice23A: stripePriceList?.experiment === PRICING_EXPERIMENT.TEST_PRICE_23_A,
			testPrice24A: stripePriceList?.experiment === PRICING_EXPERIMENT.TEST_PRICE_24_A,
		}),
	}),
	mapProps(
		({
			plan,
			period,
			cleverbridge,
			priceList,
			stripePriceList,
			isBusinessModel01,
			testPrice23A,
			testPrice24A,
			planName,
			planIdentifier,
			language,
			lockPrice,
			actualPeriod,
			...props
		}) => ({
			...props,
			inAppData: {
				plan,
				period,
				language,
				priceList,
				stripePriceList,
				cleverbridge,
				isBusinessModel01,
				testPrice23A,
				testPrice24A,
				planName,
				lockPrice,
				actualPeriod: OPS[actualPeriod],
				planIdentifier: cleverbridge ? TYPES[planIdentifier].toLowerCase() : planIdentifier,
			},
		}),
	),
);

import chroma from 'chroma-js';

export const ChromaPoint = [
	['#113081', '#398AD6', '#61CEF0', '#CEF3FB'],
	['#760A5E', '#ED655D', '#F6BD93', '#F8F0A9'],
	['#001B52', '#516183', '#9CADC5', '#E5EDF4'],
	['#01659F', '#01C1CA', '#B0E5D3', '#FFEEB1'],
	['#007181', '#26B8B8', '#92E4B8', '#DBF0E7'],
	['#6A013A', '#AD3E3E', '#F55F5F', '#FFD6D0'],
	['#861A61', '#D85179', '#FFA3B9', '#FFE1E5'],
	['#614E4E', '#B99560', '#F1CB6B', '#FFEFC8'],
];

export const chromaGenerate = ({ color, limit }) => {
	if (!color || !color.key)
		color = {
			key: 'default',
			list: ChromaPoint[0],
		};
	return chroma.scale(color.list).mode('lch').colors(limit);
};

export const ChromaLighter = (hex, level = 3) => chroma(hex).brighten(level).hex();

import React, { FC, memo, ReactNode } from 'react';
import styled from '@emotion/styled';
import cn from 'classnames';
import { ColorType, ThemeType } from '../ThemeProvider/theme';
import { getThemeColor } from '../../utils';
import { TypographyVariant } from '../Typography';
import Icon from '../Icon';

export type RowProps = {
	desc?: ReactNode;
	descColor?: ColorType;
	descVariant?: TypographyVariant;
	disabled?: boolean;
	children: ReactNode;
	className?: string;
	theme?: ThemeType;
	label?: ReactNode;
	labelColor?: ColorType;
	labelVariant?: TypographyVariant;
	onClick?: () => void;
	renderRight?: ReactNode;
	dataTestIdChildren?: string;
};

const StyledRow = styled.div<RowProps>`
	display: flex;
	align-items: flex-start;
	padding: 12px 16px;
	transition: background-color 300ms ease-out;
	border-radius: 8px;

	:hover {
		background-color: ${({ theme: { colors }, onClick, disabled }) =>
			onClick && !disabled ? colors.grey[300] : 'transparent'};
		cursor: ${({ onClick, disabled }) =>
			disabled ? 'not-allowed' : onClick ? 'pointer' : 'inherit'};
	}
`;

const Left = styled.div<Partial<RowProps>>`
	display: flex;
	width: 192px;
	margin-right: 24px;
	align-items: center;
	flex: 1 0 auto;
	${({ theme, labelVariant }) => theme.typography[labelVariant]};
	color: ${({ theme, labelColor }) => getThemeColor(labelColor, theme)};
	text-align: left;
`;

const Middle = styled.div<RowProps>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	${({ theme, labelVariant }) => theme.typography[labelVariant]};
	${({ theme }) => theme.typography.textMedium400};
`;

const Description = styled(Middle)<RowProps>`
	color: ${({ theme, descColor }) => getThemeColor(descColor, theme)};
	${({ theme, descVariant }) => theme.typography[descVariant]};
	margin-top: ${({ theme }) => theme.helpers.spacer()}px;
	text-align: left;
`;

const Right = styled.div<RowProps>`
	display: flex;
	text-align: right;
	align-items: center;
	flex: 2;
	justify-content: flex-end;
`;

const Row = styled.div<RowProps>`
	display: flex;
	align-items: center;
	width: 100%;
`;

const Col = styled.div<RowProps>`
	display: flex;
	align-items: center;
	flex: 8;
	flex-direction: column;
`;

const SectionRow: FC<RowProps> = ({
	children,
	className,
	labelColor,
	label,
	onClick,
	labelVariant,
	disabled,
	descColor,
	descVariant,
	desc,
	renderRight,
	dataTestIdChildren,
}: RowProps) => (
	<StyledRow
		className={cn('styledRow', className)}
		onClick={disabled ? undefined : onClick}
		disabled={disabled}
	>
		<Col>
			<Row>
				{label && (
					<Left labelColor={labelColor} labelVariant={labelVariant} className="left">
						{label}
					</Left>
				)}
				<Middle className="middle" data-test-id={dataTestIdChildren}>
					{children}
				</Middle>
			</Row>
			{desc && (
				<Row>
					<Left />
					<Description descVariant={descVariant} descColor={descColor} className="desc">
						{desc}
					</Description>
				</Row>
			)}
		</Col>
		{!disabled && (renderRight || onClick) && (
			<Right className="right">
				{renderRight}
				{onClick && <Icon name="chevron-right-24" color="grey_shades_with_blue[500]" />}
			</Right>
		)}
	</StyledRow>
);

SectionRow.defaultProps = {
	descColor: 'grey_shades_with_blue[500]',
	descVariant: 'textRegular250',
	labelColor: 'grey_shades_with_blue[500]',
	labelVariant: 'textRegular300',
};

export default memo(SectionRow);
